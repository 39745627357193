.TaskListContainer {
  padding:5px;
  margin:5px;
  border:1px dashed white;
  flex:1;
  display:flex;
  flex-direction: column;
}

.TaskList {
  flex:1;
  padding: 30px;
  overflow: hidden;
  overflow-y: auto;
}

.NoTaskContainer {
 color:white;
 display:flex;
 flex-direction: column;
 padding-top:10px;
 align-items: center;
 justify-content: center;
}

.NoTaskContainerText{
  margin-bottom: 20px;
  margin-top: 10px;
}





