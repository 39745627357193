.button{
    height: 50px;
    padding: 0 15px;
    border-radius: 6px;
    border: none;
    font-size: 17px;
    min-width: max-content;
    cursor: pointer;
    transition: background-color .3s ease, opacity .3s ease, color .3s ease;
    @media (min-width:992px) {
        &:hover{
            opacity: .7;
        }
    }
}

.textonly{
    width: max-content;
    height: max-content;
    border-radius: 0;
    padding: 0;
    background-color: transparent !important;
}

.empty{
    background-color: transparent;
    border: 1px solid black;
    border-radius: 6px;
}

.border{
    border: 1px solid #000;
}