.FPContainer {
  background:rgba(0,0,0, 0.8);
  padding: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

.FPClose{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 13px;
  top: 13px;
}

.FPBox{
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  padding: 40px 30px;
  border-radius: 20px;
  position: relative;
  display:flex;
  flex-direction: column;
  text-align: center;
}
@keyframes appear-from-top{
  from{
    transform: translateY(-1500px);
  }
  to{
    transform: translateY(0px);
  }
}
.appear{
  animation: appear-from-top .3s ease;
}
.disappear{
  animation: appear-from-top .3s ease reverse;
}


.FPSelect{
  height:50px;
  width:auto;
  border: none;
  border-radius: 8px;
  padding: 0 10px;
  font-size: 16px;
  cursor: pointer;
}
