.titleBar{
  height: max-content;
  min-height: 60px;
  max-height: 60px;
  display:flex;
  align-items: center;
  padding:10px 15px;
  background:#2e2e2e;
  color:white;
  position: relative;
  z-index: 6;
}

.TitleLogo{
  height:60px;
  width:60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.TitleText{
  color:#009fe3;
  font-weight: bold;
}

.TitleUserIcon{
  height:40px;
  width:40px;
  object-fit: cover;
  margin-left: 10px;
  border-radius: 50%;
}


.MyTitle button{
  display:flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border:none;
  background:#009fe3;
  color:#ffffff;
  outline:none;
  font-size: 14px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding:10px 14px;
}

.MyTitle button:active{
  outline:none;
  color:black;
}

.MyTitle button:focus{
  outline:0;
} 

.MyTitleSpacer{
  flex:1;
}

.SocketConnected {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background:lightgreen;
  margin-right:10px;
}

.SocketDisconnected {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background:red;
  margin-right:10px;
}