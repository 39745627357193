.AlertMessagePage {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index: 1000;
}

.AlertMessageContainer{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-color: white;
  border: 1px solid #f3f3f3; /* Light grey */
  display:table;
  border-radius: 10px;
  margin:0px auto;
  width:300px;
  overflow: hidden;
}

.AlertMessageTitle{
  text-align: center;
  font-weight: bold;
  margin: 20px;
  font-size: 18px;
  line-height: 22px;
}

.AlertMessageText {
  white-space: pre;
  margin:20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
}

.AlertMessageButtonContainer {
  text-align: center;
  overflow: hidden;
}

.AlertMessageButtonCancel{
  padding: 0;
  height:50px;
  border: none;
  border: 1px solid white;
  border-bottom-left-radius: 8px;
  background: none;
  width:50%;
  color:white;
  font-weight: bold;
}

.AlertMessageButtonAccept{
  padding: 0;
  height:50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  background: none;
  width:50%;
  color:white;
  font-weight: bold;
}

.AlertMessageButtonAcceptSingle{
  padding: 0;
  height:50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: none;
  width:100%;
  color:white;
  font-weight: bold;
}





