.MMenu_P {
  font-size: 20px;
  margin-top: 5px;
  padding-top: 5px;
}

.Hey_Title {
	font-family: 'Sedgwick Ave', cursive;
  font-size: 50px;
  line-height: 60px;
  color: #fdc009;
}

.Hey_Copy {
	font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 36px;
}

.MMenu_Hey {
	margin-top: 20px;
}
