.MessageItemContainer{
  display:flex;
  flex:1;
  flex-direction: column;
}

.MessageCell {
  display: flex;
  margin-bottom: 20px;
  @media (min-width:768px) {
    margin-bottom: 30px;
  }
}

.MessageItemSpacer{
  height:6px;
}

.ChatIconContainerUser{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.messageIconWrap{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.MessageChatIcon{
  width:50px;
  height:50px;
  border-radius: 50%;
}

.MessageContentApp{
  background: #cdcdcd;
  color:black;
  border-radius: 10px;
  padding:10px;
  width:10px;
  height:auto;
  flex:1;
  text-align: left;
  flex-direction: column;
  display:flex;
}

.messageContent{
  background: #009fe3;
  color:white;
  border-radius: 5px;
  padding:10px;
  width:100%;
  height:auto;
  flex:1;
  flex-direction: column;
  display:flex;
  position: relative;

  &::after{
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 15px;
    transform: translateX(50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background-color: #009fe3;
    @media (min-width:768px) {
      bottom: 22px;
    }
  }
}
.messageUser{
  text-align: right;
}
.messageApp{
  &::after{
    right: auto;
    left: 0;
    transform: translateX(-50%) rotate(45deg);
  }
}


.MediaThumbnail{
  background: #2e2e2e;
  padding:8px;
  border-radius: 10px;
  object-fit: cover;
  margin: 0px auto;
  max-width: 80%;
  max-height:400px;
}

.MediaPlaceHolder{
  display:none;
}

.AudioPlayer{
  align-self: flex-end;
}

.PaymentContainer{
  border-radius: 10px;
  display:flex;
  flex-direction: column;
}

.PaymentTitle{
  background:black;
  color:white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding:10px;font-weight: bold;
}

.PaymentDetailsContainer{
  display:flex;
  flex:1;
}

.PaymentDetails{
  display:flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: #009fe3;
  flex:1;
  padding:10px;
  border-bottom-left-radius: 10px;
}

.PaymentStatusContainer{
  border:1px solid white;
  padding:10px;
  border-bottom-right-radius: 10px;
}

.PaymentAmount{
  font-weight: bold;
  padding:10px;
  border-top:1px solid white;
  border-left:1px solid white;
  border-right:1px solid white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.PaymentStatusPending{
  background:rgba(190, 190, 0, 1);
  text-align: center;
  padding:10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color:white;
}

.PaymentStatus{
  text-align: center;
  background:green;
  padding:10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color:white;
}

.VendorContainer{
  display:flex;
  flex-direction: row;
  overflow:scroll;
  border-radius: 10px;
  justify-content: center;
}

.VendorCell{
  display:flex;
  flex-direction: column;
  margin-right: 10px;
  height:500px;
  max-width:300px;
}

.VendorTitle{
  display:flex;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background:black;
  padding:20px;
  font-weight: bold;
  justify-content: space-between;
}

.VendorDetails{
  padding:10px;
  display:flex;
  justify-content: center;
  flex-direction: column;
  flex:1;
  background: white;
  color:#2e2e2e;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.VendorDetailsItem{
  margin-bottom: 10px;
}

.VendorMap{
}

.TimeStamp{
  font-size: 10px;
  margin:4px 0px;
  margin-bottom: 8px;
}

.MessagePadding {
  @media (min-width:992px) {
    width:25%;
  }
}

