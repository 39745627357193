.NewTaskContainer{
  background:rgba(0,0,0, 0.8);
  padding: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 9;
  /* justify-content: center; */
}

.NTClose{
  position: absolute;
  right: 13px;
  top: 13px;
  color: #fff;
}

.FileSystem{
  display: none;
}

.NewTaskBlock{
  background-color: #000;
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  padding: 30px 10px;
  border-radius: 20px;
  position: relative;
  display:flex;
  flex-direction: column;
  text-align: center;
  color:white;
  @media (min-width:576px) {
    padding: 40px 30px;
  }
}

.NewTaskTitleContainer{
  display:flex;
  align-items: center;
}

.NewTaskTitle{
  font-weight: bold;
  text-align: center;
  flex:1;
  margin-bottom: 20px;
}

.NewTaskInputContainer{
  display:flex;
  align-items: flex-end;
}


.NewTaskContainer img{
  width:40px;
  height:40px;
  object-fit: contain;
}

.NewTaskContainer button:active{
  border:none;
  background: #009fe3;
  border-radius: 10px;
}

.NewTaskContainer button:focus{
  outline:0;
} 

.InputWrapper{
  display:flex;
  flex:1;
  margin:0px 10px;
  background: #cccccc;
  border: 1px solid #009fe3;
  border-radius: 10px;
  padding:2px;
}

.NewTaskInput{
  flex:1;
  font-size: 14px;
  resize: none;
  outline:none;
  overflow: hidden;
  background:none;
  border:none
}

.NewTaskMediaAttachmentList{
  display:flex;
  /* margin-top: 4px; */
  padding:10px;
  overflow:hidden;
  align-items: center;
  border: 1px solid #009fe3;
  border-radius: 6px;
  margin-top: 20px;

}

.NewTaskMediaAttachmentContainer {
  flex:1;
  display:flex;
  flex-direction: row;
  overflow: auto;
  margin-right: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.NewTaskMediaAttachmentListItem{
  display:flex;
  background:#2e2e2e;
  margin:2px;
  border:2px solid white;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  height:70px;
  width:70px;
}

