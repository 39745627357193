html{
  height:100vh;
  width:100vw;
  overflow: hidden;
}

body{
  background: #2e2e2e;
  height:100vh;
  width:100vw;
}

.MyPage{
  width:100vw;
  height:100vh;
  display:flex;
  flex-direction: column;
}

.BlueUIButton{
  height:40px;
  border-radius: 15px;
  background:#009fe3;
  border:none;
  font-size: 14px;
  font-weight: bold;
  color:white;
}

.BlueUIButton:active{
  outline:none;
  color:#009fe3;
  background:white;

}

.BlueUIButton:focus{
  outline:none;
}

.WhiteUIButton{
  height:40px;
  border-radius: 15px;
  background:white;
  border:none;
  font-size: 14px;
  font-weight: bold;
  color:#009fe3;
}

.WhiteUIButton:active{
  outline:none;
  background:#009fe3;
  color:white;
}

.WhiteUIButton:focus{
  outline:none;
}

.ErrorUIButton{
  height:40px;
  border-radius: 15px;
  background:darkred;
  border:none;
  font-size: 14px;
  font-weight: bold;
  color:white;
}

.ErrorUIButton:active{
  outline:none;
  background:darkred;
  color:darkgrey;
}

.ErrorUIButton:focus{
  outline:none;
}

.ClearUIButton{

}







