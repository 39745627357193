.backdropStyle {
  position: fixed;
  top: 0;
  left: 0;
  background: #ac00ff;
  padding: 50px;
  z-index: 999;
  width: 100%;
  height: 2000px;
  background-image: url('../../Assets/Img/background.svg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	opacity: 1;
  overflow: visible;
  overflow-y: auto;
}

.modalStyle	{
  height: 2000px;
  overflow-y: auto;
  padding: 20px;
  background: #ac00ff;
}

.closeButton {
	position: fixed;
	top: 20px;
	right: 20px;
	background: none;
	border: 1px solid #fff;
	color: #fdc009;
}
