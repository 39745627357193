.wrapper{
    display: block;
    width: auto;
    position: relative;

    &_100{
        width: 100%;
    }
    &_flex-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_flex-end{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    &_flex-column-center{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &_flex-column-end{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
    &_page{
        min-width: 100vw;
        min-height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
    }
    &_left-half{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    &_right-half{
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &_tasks-list{
        flex:1;
        padding: 30px 15px;
        overflow-x: hidden;
        overflow-y: auto;
        @media (min-width:576px) {
            padding: 30px;
        }
    }
    &_cover{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(#000, .6);
        z-index: 10;
        transition: opacity .3s ease;
        &-hide{
            opacity: 0;
        }
        &-fixed{
            position: fixed;
        }
    }



    &-main-app{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }
    &-sign{
        // padding: 30px 0;
        overflow-y: auto;
    }
    &-creation-options{
        padding: 15px 0;
        @media (min-width:992px) {
            padding: 30px 0;
        }
    }
    &-type-switch{
        padding: 15px 0;
        @media (min-width:992px) {
            padding: 30px 0;
        }
    }
}