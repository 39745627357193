@charset "UTF-8";
html {
  box-sizing: border-box;
  font-family: sans-serif; }

body {
  line-height: 1;
  font-weight: normal;
  margin: 0;
  padding: 0;
  background-color: #666666;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

input, a, button, select, div, textarea {
  text-decoration: none !important;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
  input:active, a:active, button:active, select:active, div:active, textarea:active {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }
  input:hover, a:hover, button:hover, select:hover, div:hover, textarea:hover {
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

*,
*::before,
*::after {
  box-sizing: inherit; }

.AppContainer {
  overflow: hidden;
  position: absolute;
  right: 0px;
  /*will be 0*/
  left: 0px;
  top: 0px;
  bottom: 0px; }

.PageContainer {
  position: absolute;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  flex-direction: column; }

.ContentContainer {
  position: relative;
  top: 30%;
  transform: translateY(-50%); }

.CenterTitle {
  text-align: center;
  padding: 10px; }

.MainAppContainer {
  height: 100%; }

@font-face {
  font-family: 'fontello';
  src: local("fontello"), local("fontello"), url("./Assets/Fonts/fontello.eot"), url("./Assets/Fonts/fontello.eot#iefix") format("embedded-opentype"), url("./Assets/Fonts/fontello.woff2") format("woff2"), url("./Assets/Fonts/fontello.woff") format("woff"), url("./Assets/Fonts/fontello.ttf") format("truetype"), url("./Assets/Fonts/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-left:before {
  content: '\e800'; }

/* '' */
.icon-logout:before {
  content: '\e805'; }

/* '' */
.icon-paper-plane:before {
  content: '\f1d8'; }

/* '' */
.icon-doc-add:before {
  content: '\e801'; }

.icon-pencil:before {
  content: '\e802'; }

/* '' */
.icon-credit-card:before {
  content: '\e803'; }

.icon-cancel:before {
  content: '\e804'; }

.color-white {
  color: #FFFFFF !important; }

.color-black {
  color: #000 !important; }

.color-default-gray {
  color: #1D1D1D !important; }

.fz-10 {
  font-size: 10px !important;
  line-height: 12px !important; }
  @media (min-width: 375px) {
    .fz-10 {
      font-size: 10px !important;
      line-height: 14px !important; } }

.fz-11 {
  font-size: 11px !important;
  line-height: 13px !important; }
  @media (min-width: 375px) {
    .fz-11 {
      font-size: 11px !important;
      line-height: 15px !important; } }

.fz-12 {
  font-size: 12px !important;
  line-height: 14px !important; }
  @media (min-width: 375px) {
    .fz-12 {
      font-size: 12px !important;
      line-height: 16px !important; } }

.fz-13 {
  font-size: 13px !important;
  line-height: 15px !important; }
  @media (min-width: 375px) {
    .fz-13 {
      font-size: 13px !important;
      line-height: 17px !important; } }

.fz-14 {
  font-size: 13px !important;
  line-height: 16px !important; }
  @media (min-width: 375px) {
    .fz-14 {
      font-size: 14px !important;
      line-height: 18px !important; } }

.fz-15 {
  font-size: 14px !important;
  line-height: 17px !important; }
  @media (min-width: 375px) {
    .fz-15 {
      font-size: 15px !important;
      line-height: 19px !important; } }

.fz-16 {
  font-size: 15px !important;
  line-height: 18px !important; }
  @media (min-width: 375px) {
    .fz-16 {
      font-size: 16px !important;
      line-height: 20px !important; } }

.fz-18 {
  font-size: 17px !important;
  line-height: 20px !important; }
  @media (min-width: 375px) {
    .fz-18 {
      font-size: 18px !important;
      line-height: 22px !important; } }

.fz-20 {
  font-size: 19px !important;
  line-height: 22px !important; }
  @media (min-width: 375px) {
    .fz-20 {
      font-size: 20px !important;
      line-height: 24px !important; } }

.fz-25 {
  font-size: 24px !important;
  line-height: 27px !important; }
  @media (min-width: 375px) {
    .fz-25 {
      font-size: 25px !important;
      line-height: 29px !important; } }

.fz-30 {
  font-size: 29px !important;
  line-height: 32px !important; }
  @media (min-width: 375px) {
    .fz-30 {
      font-size: 30px !important;
      line-height: 34px !important; } }

.min-w-300 {
  min-width: 300px; }

.max-w-100 {
  max-width: 100px; }

.min-h-100vh {
  min-height: 100vh; }

.h-40 {
  height: 40px !important; }

.uppercase {
  text-transform: uppercase !important; }

.break-word {
  word-break: break-all; }

.bold {
  font-weight: bold !important; }

.text-left {
  text-align: left !important; }

.ai-end {
  align-items: flex-end; }

.flex-1 {
  flex: 1; }

.w-100 {
  width: 100% !important; }

.d-b {
  display: block !important; }

.no-radius {
  border-radius: 0 !important; }

.zi-2 {
  z-index: 2; }

@media (min-width: 992px) {
  .hide-lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hide-xl {
    display: none !important; } }

@media (max-width: 991px) {
  .show-lg {
    display: none !important; } }

@media (max-width: 1199px) {
  .show-xl {
    display: none !important; } }

.wrapper {
  display: block;
  width: auto;
  position: relative; }
  .wrapper_100 {
    width: 100%; }
  .wrapper_flex-center {
    display: flex;
    justify-content: center;
    align-items: center; }
  .wrapper_flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .wrapper_flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .wrapper_flex-column-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; }
  .wrapper_page {
    min-width: 100vw;
    min-height: 100vh;
    max-width: 100vw;
    max-height: 100vh; }
  .wrapper_left-half {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .wrapper_right-half {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .wrapper_tasks-list {
    flex: 1;
    padding: 30px 15px;
    overflow-x: hidden;
    overflow-y: auto; }
    @media (min-width: 576px) {
      .wrapper_tasks-list {
        padding: 30px; } }
  .wrapper_cover {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10;
    transition: opacity .3s ease; }
    .wrapper_cover-hide {
      opacity: 0; }
    .wrapper_cover-fixed {
      position: fixed; }
  .wrapper-main-app {
    width: 100vw;
    height: 100vh;
    overflow: hidden; }
  .wrapper-sign {
    overflow-y: auto; }
  .wrapper-creation-options {
    padding: 15px 0; }
    @media (min-width: 992px) {
      .wrapper-creation-options {
        padding: 30px 0; } }
  .wrapper-type-switch {
    padding: 15px 0; }
    @media (min-width: 992px) {
      .wrapper-type-switch {
        padding: 30px 0; } }

.ml-10 {
  margin-left: 10px; }

.ml-15 {
  margin-left: 15px; }

.ml-25 {
  margin-left: 25px; }

.mr-10 {
  margin-right: 10px; }

.mr-15 {
  margin-right: 15px; }

.mt-3 {
  margin-top: 3px; }

.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-50 {
  margin-top: 50px; }

.mt-80 {
  margin-top: 80px; }

.p-5 {
  padding: 5px !important; }

.p-10 {
  padding: 10px !important; }

.pr-5 {
  padding-right: 5px; }

.pt-30 {
  padding-top: 30px; }

.pt-50 {
  padding-top: 50px; }

.pb-30 {
  padding-bottom: 30px; }

.hamburger {
  width: 40px;
  height: 40px;
  position: relative; }
  .hamburger__item {
    display: block;
    width: 20px;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px; }
    .hamburger__item::before, .hamburger__item::after {
      content: '';
      display: block;
      width: 20px;
      height: 3px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: inherit;
      border-radius: 10px; }
    .hamburger__item::before {
      transform: translateY(-7px); }
    .hamburger__item::after {
      transform: translateY(7px); }
