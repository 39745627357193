.taskCard{
  width: 100%;
  min-height: 90px;
  position: relative;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
  @media (min-width:992px) {
    transition: opacity .3s ease;
    &:hover{
      opacity: .7;
    }
  }

  &__left{
    padding-right: 10px;
    min-width: 60px;
  }
  &__right{
    padding: 5px 0;
    border-left: 1px solid #000;
    padding-left: 10px;
    padding-right: 15px;
    text-align: left;
  }

  &__arrow{
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    right: 13px;
    top: 50%;
    border-top: 2px solid black;
    border-right: 2px solid black;
    transform: translateY(-50%) rotate(45deg);
  }
}





// .TaskItemContainer {
//   background: white;
//   margin-bottom:2px;
//   margin:auto 10;
// }

// .TaskItemContainer button:focus{
//   outline:0;
// }

// .TaskItemContainerSelected {
//   background: #cccccc;
//   margin-bottom:2px;
//   margin:auto 10;
// }

// .TaskItemContainerSelected button:focus{
//   outline:0;
// }

// .TaskItemPendingContainer{
//   background: #009fe3;
//   margin-bottom:2px;
//   margin:auto 10;
// }

// .TaskItemPendingContainer button:focus{
//   outline:0;
// }

// .TaskItemTitle{
//   font-weight: bold;
//   font-size: 14px;
//   text-align: left;
//   margin-bottom: 4px;
//   max-width: 100%;
//   word-break: break-all;
// }

// .TaskItemTitlePending{
//   font-weight: bold;
//   font-size: 14px;
//   text-align: left;
//   margin-bottom: 4px;
//   color:white;
// }


// .TaskItemState{
//   font-size: 12px;
//   width:50%;
//   text-align: left;
// }

// .TaskItemCreated{
//   margin:10px;
//   min-width:30%;
// }

// .TaskItemArrow{
//   width:26px;
//   height:26px;
// }

// .TaskItemDetailsContainer {
//   flex:1;
//   text-align: left;
// }


// .TaskItemButton {
//   display:flex;
//   background:none;
//   border: none;
//   width:100%;
//   padding:8px;
//   align-items: center;
// }

